// Card overlay slide
.card.card-overlay-slide {
    
    --#{$prefix}card-overlay-slide-bg: #{$card-overlay-slide-bg};
    --#{$prefix}card-overlay-bg-opacity: #{$card-overlay-bg-opacity};
    --#{$prefix}card-overlay-slide-bg-opacity: #{$card-overlay-slide-bg-opacity};
    --#{$prefix}card-overlay-slide-from-bottom: #{$card-overlay-slide-from-bottom};
    --#{$prefix}card-overlay-slide-transition: #{$transition-base};

    overflow: hidden;

    & .card-img-overlay {
        top: unset;
        transform: translateY(calc(100% - var(--#{$prefix}card-overlay-slide-from-bottom)));
        transition: var(--#{$prefix}card-overlay-slide-transition);
        background: rgba(var(--#{$prefix}card-overlay-slide-bg), var(--#{$prefix}card-overlay-bg-opacity));
        z-index: 1;

        & .card-link, & .card-text{
            opacity: 0;
            transition: var(--#{$prefix}card-overlay-slide-transition);
        }
    }

    &:hover {

        &::after {
            background: rgba(var(--#{$prefix}card-overlay-slide-bg),0);
        }

        & .card-img-overlay {
            transform: translateY(0);
            background: rgba(var(--#{$prefix}card-overlay-slide-bg), var(--#{$prefix}card-overlay-slide-bg-opacity));

            & .card-link, & .card-text{
                opacity: 1;
            }
        }
    }
}

// Card content float
.card.card-content-float {

    --#{$prefix}card-content-float-image-padding-xxl: #{$card-content-float-image-padding-xxl};
    --#{$prefix}card-content-float-image-padding-xl: #{$card-content-float-image-padding-xl};
    --#{$prefix}card-content-float-body-width-xxl: #{$card-content-float-body-width-xxl};
    --#{$prefix}card-content-float-body-width-xl: #{$card-content-float-body-width-xl};
    --#{$prefix}card-content-float-body-padding: #{$card-content-float-body-padding};
    --#{$prefix}card-content-float-body-bottom: #{$card-content-float-body-bottom};
    --#{$prefix}card-content-float-body-top: #{$card-content-float-body-top};

    @include media-breakpoint-up(xl) {
        position: relative;

        & .card-img-top,
        & .card-img-bottom {
            padding-left: var(--#{$prefix}card-content-float-image-padding-xl);
            border-radius:#{$border-radius};
            overflow:hidden;
        }

        & .card-img-top figure,
        & .card-img-bottom figure,
        & .card-img-top img,
        & .card-img-bottom img {
            border-radius:#{$border-radius};
            overflow:hidden;
        }

        &.card-content-float-right .card-img-top,
        &.card-content-float-right .card-img-bottom{
            padding-left:unset;
            padding-right: var(--#{$prefix}card-content-float-image-padding-xl);
        }

        & .card-body {
            position: absolute;
            padding: var(--#{$prefix}card-content-float-body-padding);
            top: var(--#{$prefix}card-content-float-body-top);
            bottom: var(--#{$prefix}card-content-float-body-bottom);
            width: var(--#{$prefix}card-content-float-body-width-xl);
            z-index:3;
            border-radius:#{$btn-border-radius};
        }

        &.card-content-float-right .card-body {
            right:0;
        }
    }

    @include media-breakpoint-up(xxl) {
        & .card-img-top,
        & .card-img-bottom {
            padding-left: var(--#{$prefix}card-content-float-image-padding-xxl);
        }

        &.card-content-float-right .card-img-top,
        &.card-content-float-right .card-img-bottom {
            padding-right: var(--#{$prefix}card-content-float-image-padding-xxl);
        }

        & .card-body {
            width: var(--#{$prefix}card-content-float-body-width-xxl);
        }
    }
}

// Card Selected
.card.card-selected {
    border-color: #{$primary};

    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 40px 40px 0;
        border-color: transparent #{$primary} transparent transparent;
    }

    &:after {
        content: "\f1c1";
        top: 3px;
        right: 5px;
        position: absolute;
        color: #fff;
        font-family: icons;
        font-weight: 900;
        font-size: 70%;
    }
}
