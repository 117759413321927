// Hero banner
@include media-breakpoint-down(md) {
    .hero-carousel,
    .hero-image,
    .hero-video {
        .carousel-item,
        .image-wrapper,
        .video-wrapper {
            height: 465px;
        }

        .carousel-caption,
        .carousel-caption-outside,
        .image-caption,
        .video-caption {
            padding-top: 4rem;
        }

        .check-room {
            background-color: rgba(var(--bs-black-rgb), 1);

            .card-check-room {
                background-color: rgba(var(--bs-black-rgb), 1);
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .hero-carousel,
    .hero-image,
    .hero-video {
        .carousel-item .bg-image,
        .image-wrapper,
        .video-wrapper {
            height: 100vh;
        }

        .check-room {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 10;
            margin-bottom: 7rem;

            .card-check-room {
                background-color: rgba(var(--bs-black-rgb), 0.75);
            }
        }
    }
}
