// Scroll top
.scroll-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 25px;
    bottom: 25px;
    z-index: 999;
    background: rgba(var(--#{$prefix}primary-rgb), 0.5);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transition: all 0.4s;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1.15rem;

    &:hover {
        background: rgba(var(--#{$prefix}primary-rgb), 0.85);
    }

    &.active {
        visibility: visible;
        opacity: 1;
    }
}

// Code
.code-wrapper {
    margin-bottom: 0;
    position: relative;

    .btn-clipboard {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        cursor: pointer;
        transform: none;
        @include font-size(0.65rem);
        padding: 0.1rem .45rem 0.1rem;
        z-index: 3;
    }

    & pre>code[class*=language-] {
        padding: 2.25em;
    }
}

/* Font */
.ff-heading {
    font-family: 'Cinzel', serif !important;
}

.ff-sub {
    font-family: 'Red Hat Text', sans-serif !important;
}

/* Letter spacing */
.ls-1 {
    letter-spacing: 0.15rem;
}

.ls-2 {
    letter-spacing: 0.25rem;
}

.ls-3 {
    letter-spacing: 0.35rem;
}