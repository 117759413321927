#header:not(.bg-white) .navbar::before {
    content: "";
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    position: absolute;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.95) 0%, rgba(0, 0, 0, 0) 100%);
    z-index: -1;
}
